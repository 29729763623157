.popper-div {
    position: relative;
    background-color: #333;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.4;
    outline: 0;
    padding: 5px 9px;
}

.legand-div {
    position: relative;
    background-color: #fff;
    color: black;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.4;
    outline: 0;
    padding: 5px 9px;
}
